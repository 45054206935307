import React, { useState } from 'react'
import MobileMenu2 from '../MobileMenu2/MobileMenu2';
import { totalPrice } from "../../utils";
import { connect } from "react-redux";
import { removeFromCart } from "../../store/actions/action";
import { Link } from 'react-scroll'
import { NavLink } from 'react-router-dom'


const Header3 = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <header id="header" className={props.topbarNone}>
            <div className={`wpo-site-header ${props.hclass}`}>
                <nav className="navigation navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                                <div className="mobail-menu">
                                    <MobileMenu2 />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-6">
                                <div className="navbar-header">
                                    <NavLink onClick={ClickHandler} className="navbar-brand" to="/home">Hernan & Irina</NavLink>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-1 col-1">
                                <div id="navbar" className="collapse navbar-collapse navigation-holder">
                                    <button className="menu-close"><i className="ti-close"></i></button>
                                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link activeClass="active" to="couple" spy={true} smooth={true}
                                                  offset={-100} duration={500}>Couple</Link></li>
                                        <li><Link activeClass="active" to="story" spy={true} smooth={true}
                                                  duration={500} offset={-100}>Story</Link></li>
                                        <li><Link activeClass="active" to="rsvp" spy={true} smooth={true} duration={500}
                                                  offset={-100}>RSVP</Link></li>
                                        <li><Link activeClass="active" to="event" spy={true} smooth={true} offset={-80}
                                                  duration={500}>Events</Link></li>
                                        <li><Link activeClass="active" to="gifts" spy={true} smooth={true} offset={-80}
                                                  duration={500}>Gift Ideas</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}

const mapStateToProps = (state) => {
    return {
        carts: state.cartList.cart,
    };
};
export default connect(mapStateToProps, { removeFromCart })(Header3);