import React from 'react';
import {BrowserRouter, Route, Routes,} from "react-router-dom";
import HomePage6 from '../HomePage6/HomePage6';

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage6 />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
