import React from 'react'
import { Slide } from "react-awesome-reveal";
import sImg1 from '../../images/story/4.png'
import sImg2 from '../../images/story/5.png'
import sImg3 from '../../images/story/6.png'

import shape1 from '../../images/story/story-shape.png'


const Storys = [
    {
        sImg: sImg1,
        shape: shape1,
        animation: '1000',
        title: 'How we met',
        date: '17 August 2017',
        description: 'We met online and instantly matched. From our first conversation, it was clear that we had a special bond. Our interests, values, and sense of humor aligned perfectly, and we quickly realized we had found something truly unique. It wasn\'t long before our virtual connection blossomed into a wonderful real-life relationship.'   },
    {
        sImg: sImg2,
        shape: shape1,
        animation: '1200',
        title: 'I proposed, she said yes',
        date: '04 November 2023',
        description: 'I proposed to her at Angel Falls in Venezuela, a place as breathtaking as the moment itself. She had no idea what was coming, which made the surprise all the more perfect. As we stood there, surrounded by the stunning natural beauty of the falls, I got down on one knee and asked her to spend the rest of her life with me. Her joyful "yes" made it a moment we\'ll cherish forever.',
        order1: 'order-lg-2 order-1',
        order2: 'order-lg-1 order-2',
    },
    {
        sImg: sImg3,
        shape: shape1,
        animation: '1400',
        title: 'Our wedding day',
        date: '18 August 2024',
        description: 'We are thrilled to celebrate our wedding day in the beautiful town of Zoetermeer, in The Netherlands. Surrounded by our beloved family and friends, we will exchange our vows in a ceremony filled with love and joy. The day will be adorned with delicious food, wonderful music, and heartfelt moments, creating memories that will stay with us forever.',
    }

]


const StorySection6 = (props) => {
    return (

        <section className="wpo-story-section-s6 section-padding" id="story">
            <div className="container">
                <div className="wpo-story-wrap">
                    <div className="row">
                        {
                            Storys.map((story, st) => (
                                <div className="col col-lg-4 col-md-6 col-12" key={st}>
                                    <Slide direction="up" duration={story.animation} triggerOnce="true">
                                        <div className="wpo-story-item">
                                            <div className="wpo-story-img">
                                                <div className="wpo-story-img-inner">
                                                    <img src={story.sImg} alt="" />
                                                </div>
                                                <div className="flower-shape">
                                                    <img src={story.shape} alt="" />
                                                </div>
                                            </div>
                                            <div className="wpo-story-content">
                                                <span>{story.date}</span>
                                                <h2>{story.title}</h2>
                                                <p>{story.description}</p>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                            ))

                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StorySection6;