import React from 'react'
import { Slide } from "react-awesome-reveal";
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/event/7.jpg'
import sImg2 from '../../images/event/8.jpg'
import sImg3 from '../../images/event/9.jpg'
import LocationMap from './Modal'


const Events = [
    {
        Simg: sImg1,
        title: 'The Arrival of the Guests',
        li1: 'Sunday, 18 August. 2024 13:30 – 14:00 ',
        li2: 'Het Wapen van Zoetermeer: Zegwaartseweg 31, 2723 PA Zoetermeer',
        li3: '+31 79 760 0055',
        animation: '1200',
    },
    {
        Simg: sImg2,
        title: 'The Ceremony',
        li1: 'Sunday, 18 August. 2024 14:00 – 15:00 ',
        li2: 'Het Wapen van Zoetermeer: Zegwaartseweg 31, 2723 PA Zoetermeer',
        li3: '+31 79 760 0055',
        animation: '1400',
    },
    {
        Simg: sImg3,
        title: 'The Toasts',
        li1: 'Sunday, 18 August. 2024 15:00 – 16:00 ',
        li2: 'Het Wapen van Zoetermeer: Zegwaartseweg 31, 2723 PA Zoetermeer',
        li3: '+31 79 760 0055',
        animation: '1600',
    },
    {
        Simg: sImg3,
        title: 'The Drinks',
        li1: 'Sunday, 18 August. 2024 16:00 – 17:30 ',
        li2: 'Het Wapen van Zoetermeer: Zegwaartseweg 31, 2723 PA Zoetermeer',
        li3: '+31 79 760 0055',
        animation: '1600',
    },
    {
        Simg: sImg3,
        title: 'The Dinner',
        li1: 'Sunday, 18 August. 2024 17:30 – 20:00 ',
        li2: 'Het Wapen van Zoetermeer: Zegwaartseweg 31, 2723 PA Zoetermeer',
        li3: '+31 79 760 0055',
        animation: '1600',
    },
    {
        Simg: sImg3,
        title: 'The Coffee Time',
        li1: 'Sunday, 18 August. 2024 20:00 – 20:30 ',
        li2: 'Het Wapen van Zoetermeer: Zegwaartseweg 31, 2723 PA Zoetermeer',
        li3: '+31 79 760 0055',
        animation: '1600',
    },
    {
        Simg: sImg3,
        title: 'The Party',
        li1: 'Sunday, 18 August. 2024 20:30 – 00:30 ',
        li2: 'Het Wapen van Zoetermeer: Zegwaartseweg 31, 2723 PA Zoetermeer',
        li3: '+31 79 760 0055',
        animation: '1600',
    },
]

const EventSectionS4 = (props) => {
    return (
        <section className="wpo-event-section-s4 section-padding" id="event">
            <div className="container">
                <SectionTitle subTitle={'Our Wedding'} MainTitle={'When & Where'} />
                <div className="wpo-event-wrap">
                    <div className="row">
                        {Events.map((event, eitem) => (
                            <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                                <Slide direction="up" duration={event.animation} triggerOnce="true">
                                    <div className="wpo-event-item">
                                        <div className="wpo-event-text">
                                            <div className="title">
                                                <h2>{event.title}</h2>
                                            </div>
                                            <ul>
                                                <li>{event.li1}</li>
                                                <li>{event.li2}</li>
                                                <li>{event.li3}</li>
                                                <li><LocationMap /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSectionS4;